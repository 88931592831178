@import url("https://rsms.me/inter/inter.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

.js-hamburger-open span:nth-child(1) {
  @apply top-2 w-0 left-1/2;
}

.js-hamburger-open span:nth-child(2) {
  @apply rotate-45;
}

.js-hamburger-open span:nth-child(3) {
  @apply -rotate-45;
}

.js-hamburger-open span:nth-child(4) {
  @apply top-2 w-0 left-1/2;
}

.js-mobileNav-open {
  @apply flex;
}
